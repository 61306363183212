import { Component, OnInit } from '@angular/core';
import { KioskapiService } from '../services/kioskapi.service';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { cart_time, images_display_timer, DOMAIN_URL, KIOSK_AUTO_LOGIN, menu_items_timer, modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, terminalCheck, } from '../common/api';

@Component({
  selector: 'app-ella-max-drink-modal',
  templateUrl: './ella-max-drink-modal.page.html',
  styleUrls: ['./ella-max-drink-modal.page.scss'],
})
export class EllaMaxDrinkModalPage implements OnInit {
  translatedText;
  notificationTest;
  checkOutBtnText;
  resetBtnText;
  orText;
  maxDrinksAllowed;
  terminalId;
  time_left_redirection_counter: any;
  telemetry_poller: any;
  terminal_modal;
  page_redirection_time_left = page_redirection_time;
  cartItemCount: number;
  totalCartAmount: number = 0;
  currency = "$";
  currencyAbbri;
  constructor(
    private router: Router,
    private kiosk_apiService: KioskapiService,
    public modalController: ModalController,
    private kioskauthservice: KioskauthService,
    private store: LocalStoreService
  ) { }

  ngOnInit() {
  }
  ionViewDidEnter() {
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      this.currencyAbbri = countryData['master_country_currency_abbreviation'];
    }
    this.getTranslatedText();
    this.terminalId = this.store.get('terminalid');
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.getCartItems();
    this.reset_time();
  }
  resetCart() {
    this.store.remove(this.store.getUser().user_information_uuid);
    this.kiosk_apiService.terminal_dismiss();
    let Autologin = this.store.get('autoLoginUrl')
    this.store.hardlogout()
    location.href = DOMAIN_URL + Autologin;
    clearInterval( this.telemetry_poller);
    clearInterval( this.terminal_modal);
    clearInterval( this.time_left_redirection_counter);
    this.modalController.dismiss();
  }
  goToCart() {
    clearInterval( this.telemetry_poller);
    clearInterval( this.terminal_modal);
    clearInterval( this.time_left_redirection_counter);
    this.router.navigateByUrl('/cart-list');
    this.modalController.dismiss();
  }
  getTranslatedText() {
    this.translatedText = this.store.get('translatedText');
  }
  reset_time() {
    clearInterval( this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time ;
  
    if( this.page_redirection_time_left = page_redirection_time){
    
    this.time_left_redirection_counter = setInterval(()=>{
      this.page_redirection_time_left -= 1;
  
      if (this.page_redirection_time_left == 0) {
        this.kiosk_apiService.terminal_dismiss()
        clearInterval(this.telemetry_poller)
        clearInterval( this.time_left_redirection_counter);
        let Autologin = this.store.get('autoLoginUrl')
        this.store.hardlogout()
        location.href = DOMAIN_URL + Autologin;

        return false;
      } 
    }, setIntervalTimeOut);
    }
   
    
  }
  ionViewDidLeave(){
    clearInterval( this.telemetry_poller);
    clearInterval( this.terminal_modal);
    clearInterval( this.time_left_redirection_counter);
  }

  goToCartList() {
    this.router.navigateByUrl('/cart-list');
    this.modalController.dismiss();
  }

  resetToCart() {
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    this.store.remove(this.store.getUser().user_information_uuid);
    this.kiosk_apiService.logEvents("CART_ITEM_REMOVED");
    this.kiosk_apiService.audit_log("CART_ITEM_REMOVED");
    clearInterval(this.time_left_redirection_counter);
    this.kiosk_apiService.terminal_dismiss()
    clearInterval(this.telemetry_poller)
    let Autologin = this.store.get('autoLoginUrl')
    this.store.hardlogout()
    location.href = DOMAIN_URL + Autologin;
  }

  getCartItems() {
    let cartDetail = this.store.getUserCart();
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
    })
  }

}
