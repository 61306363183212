import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { KioskapiService } from '../services/kioskapi.service';
import { AlertController, IonSlides, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { cart_time, images_display_timer, DOMAIN_URL, KIOSK_AUTO_LOGIN, menu_items_timer, modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, terminalCheck, } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { CartItemDetailPage } from '../cart-item-detail/cart-item-detail.page';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';
import { EllaMaxDrinkModalPage } from '../ella-max-drink-modal/ella-max-drink-modal.page';
import { HostListener } from "@angular/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  @ViewChildren("targets") private targets: QueryList<ElementRef>;
  @ViewChildren("targetsThumb") private targetsThumb: QueryList<ElementRef>;
  // @ViewChild('promoSlider')   promoSlider:               IonSlides;
  @ViewChild('menuOffset') menuOffset: ElementRef;
  
  params: any;
  telemetry_blocker = false;
  forced_transmission = "";
  payment_progress_txt = "";
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  firmware_updating = false;
  software_version = "";
  ordering_avail = true;
  time_check = "OK";
  payment_initiated = false;
  check_nega = false;
  wait_card = false;
  telemetry_poller: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  menu_item: boolean;
  is_restaurant_closed_status = "NO";
  restaurant_status_interval: any;
  page_redirection_time_left = page_redirection_time;
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any;
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  store_close_status;
  restaurant_mode;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  obj_is_restaurant_closed;
  obj_checkout;
  obj_restaurantDetails;
  obj_appsetting;
  obj_redirect_event;
  obj_menuItem;
  terminalId;
  cartItemDetail: any = [];
  haveMaxEllaCoffee: boolean
  ella_max_drinks;
  coffeeCount;
  time_left_redirection_counter: any;
  ella_limit = 5;
  terminal_modal;
  store_info
  is_ella_maintaince_mode_on:boolean
  home_banner_image:any;
  display_images:any;
  menuItems_timer:any;
  menuItems_length;
  res_settings:any;
  one_pager_kiosk: any;
  cat_show: string;
  menuItems_withoutCat:any = [];
  setLanguage: String;
  clickedEement:any = 100;
  sidebarMenus = [
    {
      name: 'Coffee',
      icon: '../../assets/image/coffee_icon.png',
      iconSelected: '../../assets/image/coffee_icon_selected.png'
    },
    {
      name: 'Tea',
      icon: '../../assets/image/tea_icon.png',
      iconSelected: '../../assets/image/tea_icon_selected.png'
    },
    {
      name: 'Other',
      icon: '../../assets/image/other_icon.png',
      iconSelected: '../../assets/image/other_icon_selected.png'
    }
  ];
  clickEventCalled:boolean = false;
  homeOptions = {
    initialSlide: 0,
    loop: true,
    autoplay: {
      delay: 10000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    // speed:750,
    pager:true
  };
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay:true,
    autoplayTimeout:5000,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };
  carouselList = [];
  showTitle = false;
  currencyAbbri;
  
  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private kiosk_apiService: KioskapiService,
    private pageLoaderService: PageLoaderService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      this.currencyAbbri = countryData['master_country_currency_abbreviation'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.check_auth();
    this.getAppSettings();
  }

  ngOnInit() {
    
  }

  is_restaurant_closed() {
    this.obj_is_restaurant_closed = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          // this.pageLoaderService.pageLoaderPersistent(false)
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
            // localStorage.removeItem('restaurant');
            // this.store.setRestaurant(res.aaData);
          }
        },
      );
  }


  ionViewWillEnter() {
    this.menu_item = false;
    this.showTitle = false;
    this.is_restaurant_closed();
    this.carouselList = [];
    this.getRestaurantDetails();
    this.check_auth();
    this.time_stop = false;
    this.time_left = cart_time;
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    this.kiosk_apiService.logEvents("HOME");
    this.kiosk_apiService.audit_log("HOME");
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.payment_printing_setting();
    this.getMenuItems();
    this.getLanguageList();
    this.getTranslatedText();
    this.reset_time();
    this.store_close_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);

    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
      // this.display_images =setInterval(()=>{
      //   this.getAppSettings();
      // },images_display_timer)
      this.menuItems_timer =setInterval(()=>{
        this.getMenuItems();
      },menu_items_timer)
     
    this.clock_Timer();
    this.orderType = this.store.get('order_type');
    this.check_auth();
    if (this, this.cartItemCount == 0) {
      clearInterval(this.time_left_counter);
    }
    this.terminalId = this.store.get('terminalid')
    if (this.store.get("orderId")) {
      this.store.remove("orderId");
    }
    if (this.store.get("sessionId")) {
      this.store.remove("sessionId");
      this.create_sessionId();
    }

  }
  create_sessionId() {
    const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('sessionId', sessionId);
  }
  ionViewDidEnter() {
    console.log("view didi enter called")
    this.ella_drink_limit();
    this.setLanguage = this.store.getLanguageCode();
    this.selectedLanguage = this.store.get("languageLocal");
    
  }
  allCategoryData;
  getMenuItems() {
    //Get Menu Details
    this.obj_menuItem = this.kiosk_apiService
      .Menu_items()
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if(this.one_pager_kiosk == false || this.one_pager_kiosk == "false" || this.one_pager_kiosk == null || this.one_pager_kiosk == undefined){
            if (response.final_response) {
              this.cat_show = "true";
              this.localMenuItems(response.final_response, "true");
              
            } else {
              this.cat_show = "true";
              this.localMenuItems(response.aaData, "true");
            }
            this.allCategoryData = response.all_drinks;
          }else{
            this.allCategoryData = response.all_drinks;
            if (response.final_response) {
              this.cat_show = "false";
              this.localMenuItems(response.final_response, "false");
              
            } else {
              this.cat_show = "false";
              this.localMenuItems(response.aaData, "false");
            }
          }
          
        }
      },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
          this.technical_error()
        }
      );
  }

  localMenuItems(apiResponse, forCat) {
    this.menu_item = true;
    const localCachedMenus = this.store.get('APP_MENUS');
    if (JSON.stringify(localCachedMenus) == JSON.stringify(apiResponse)) {
      console.log('has same menus')
      if (forCat == 'true' && Object.keys(this.menuItems).length) {
        this.menuItems = localCachedMenus;
        this.menuItems_length = Object.keys(this.menuItems).length;
        if (this.menuItems_length == 1) {
          this.clickedEement = 0;
        }
        this.showTitle = true;
      } else if (forCat == 'false' && !this.menuItems_withoutCat) {
        this.menuItems_withoutCat = localCachedMenus;
        this.menuItems_length = Object.keys(this.menuItems).length;
        if (this.menuItems_length == 1) {
          this.clickedEement = 0;
        }
        this.showTitle = true;
      } else {
        this.menuItems = localCachedMenus;
        this.menuItems_length = Object.keys(this.menuItems).length;
        if (this.menuItems_length == 1) {
          this.clickedEement = 0;
        }
        this.showTitle = true;
      }
    } else {
      console.log('has diff menus')
      if (forCat == 'true') {
        this.menuItems = apiResponse;
        this.menuItems_length = Object.keys(this.menuItems).length;
        if (this.menuItems_length == 1) {
          this.clickedEement = 0;
        }
        this.store.set('APP_MENUS', this.menuItems);
        setTimeout(() => {
          this.showTitle = true;
        }, 500);
      } else {
        this.menuItems_withoutCat = apiResponse;
        this.menuItems_length = Object.keys(this.menuItems).length;
        if (this.menuItems_length == 1) {
          this.clickedEement = 0;
        }
        this.store.set('APP_MENUS', this.menuItems_withoutCat);
        setTimeout(() => {
          this.showTitle = true;
        }, 200);
      }
    }
    
    
  }

  check_auth() {
    if (!this.kioskauthservice.is_logged_in()) {
      this.kioskauthservice.logout();
    }
  }

  async redirectOnDetailPage(item) {

    this.obj_redirect_event = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {

          this.technical_error();
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.is_popular_item == 'YES') {
        this.checkPopularItemAvailable(item);
      } else {
        this.redirectToDetailsPage(item);
      }
    }
  }
  checkPopularItemAvailable(item) {
    let data = {
      "master_menu_item_uuid": item.master_menu_item_uuid
    }
    this.kiosk_apiService.apiReq('post','mastermenuitem/verifypopularitemaddonstatus', data)
      .subscribe(
        (res) => {
          console.log(res.aaData)
          if (res.aaData.is_item_available) {
            this.addItemToCart(item);
          } else {
            this.kioskauthservice.itemNotAvailableAlert();
          }
        },
        error => {
        }
      );
    
  }
  redirectToDetailsPage(item) {
    if (item.master_menu_item_is_combo == "YES") {
      if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
        this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
      }
      else {
        this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
      // this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
    }
    else {
      this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
    }
  }
  maxOrderPopupActive = false;
  getCartItems() {
    this.maxOrderPopupActive = false;
    console.log("getCartItems called");
    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
      if ((this.cartItemCount >= this.ella_limit) && !this.maxOrderPopupActive) {
        this.maxOrderPopupActive = true;
        this.provideOrderFeedback();
      }
      if (this.timer_start == true || this.cartItemCount == 0) {
        this.time_left = cart_time;
        clearInterval(this.time_left_counter);
      }

      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;

            return false;
          }
        }, setIntervalTimeOut);
      }
    })
  }

  addItemToCart(selectedItem){
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    
    let data: any;
    let finalArray: any = [];
          data = {
            id: selectedItem.master_menu_item_uuid,
            name: selectedItem.master_menu_item_name,
            thumb: selectedItem.master_menu_item_thumbnail ? selectedItem.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
            type: selectedItem.master_menu_type,
            resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
            menu_item_total_price: +selectedItem.master_menu_item_price,
            item_addon_price: '',
            menu_item_price: +selectedItem.master_menu_item_price,
            total: +selectedItem.master_menu_item_price,
            total_base_price: +selectedItem.master_menu_item_price,
            quantity: 1, //this.quantity,
            addons: selectedItem.addons,
            note: this.store.note ? this.store.note : null,
            food_type: selectedItem.master_menu_type,
            total_quantity: 1,
            addOnToDisplay: selectedItem.addonsToDisplay
          };
          if (items) {
            finalArray.push(data);
          } else {
            finalArray.push(data);
           
          }
    if (items) {
      items.push(finalArray);
      if (this.store.isLoggedIn()) {
        this.store.setUserCart(items);
      } else { 
        this.store.setCart(items); 
      }
    } else {
      if (this.store.isLoggedIn()) {
        this.store.setUserCart(finalArray);
      } else { 
        this.store.setCart([data]); 
      }
    } 
  
  setTimeout(() => {
    this.kiosk_apiService.logEvents("ADD_TO_CART");
    this.kiosk_apiService.audit_log("ADD_TO_CART");
  }, 200);
  this.getCartItems();
  
}

  getTotalQuantity(item) {
    return item[0].total_quantity;
  }


  checkOut() {
    this.kiosk_apiService.logEvents("CHECKOUT");
    this.kiosk_apiService.audit_log("CHECKOUT");
    this.obj_checkout = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              clearInterval(this.time_left_counter);
              this.router.navigate([`payment-method`]);
            } else {

            }
          }
        },
        error => {
          this.technical_error()
        }
      );
  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
    this.kiosk_apiService.logEvents("CART_ITEM_REMOVED");
    this.kiosk_apiService.audit_log("CART_ITEM_REMOVED");
  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  /**
  * Function to add pull down feature 
  * @param event 
  */
  doRefresh(event) {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false)
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);
        } else {
          event.target.complete();
        }
      }, err => {
        event.target.complete();
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
      });
  }
  getRestaurantDetails() {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store_info = res.aaData;
          this.carouselList = this.store_info.carousels;
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          // this.getAppSettings();
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null'
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
          
          // this.promoSlider.options = this.homeOptions;
          // this.promoSlider.startAutoplay();
        }
      }, error => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader.
        this.technical_error();
      });
  }
  /**
  * Function to get app seting
  */
  getAppSettings() {

    this.obj_appsetting = this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description : environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.home_banner_image = response.aaData.app_settings.app_store_banner_description_kiosk 
      }
    }, err => {

    });
  }

  payment_printing_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.payment_printing_setting(data)
      .subscribe(
        (res) => {
          this.res_settings = res.aaData.module_permissions
          this.one_pager_kiosk = this.res_settings.one_pager_kiosk
        },
        error => {
        }
      );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  show_payment_simulation() {
    this.router.navigateByUrl('/payment-method2');
  }


  ionViewDidLeave() {
    clearInterval(this.store_close_status);
    clearInterval(this.restaurant_mode);
    clearInterval(this.telemetry_poller)
    clearInterval(this.terminal_modal)
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    clearInterval(this.time_left_redirection_counter);
    this.obj_is_restaurant_closed.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
    this.obj_menuItem.unsubscribe();
    this.obj_appsetting.unsubscribe();
    clearInterval( this.display_images)
    clearInterval(this.menuItems_timer)

  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }


  async openSheetModal(item) {
    const modal = await this.modalController.create({
      component: CartItemDetailPage,
      cssClass: 'app-cart-item-detail1',
      mode: 'md',
      componentProps: {
        data: item
      },
    });
    setTimeout(() => { modal.dismiss(); }, modalTimeout);
    return await modal.present();
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;

      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        window.location.reload();
      }
    });
  }

  async error_modal() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: "Payment Failed",
      message: "Please Tab Again",
      buttons: [
        {
          text: 'Done',
          cssClass: '',
          role: 'cancel',
        }
      ]
    });
    await alert.present();
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }


  reset_time() {
    this.time_left = cart_time;
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;

    if (this.page_redirection_time_left = page_redirection_time) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          let Autologin = this.store.get('autoLoginUrl')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
    this.clickEventCalled = false;
  }

  is_item_ella(item) {
    if (item.master_menu_type == 'ELLA') {
      return true;
    }
    else {
      return false;
    }
  }


  is_category_coffee(obj) {
    var obj3 = obj.value;
    var obj4 = obj3 ? obj3[0] : {};
    var index = "NO"
    if (obj4.master_food_category_type == "ELLA" || obj4.master_food_category_type == "ella") {
      index = "YES";
    }
    return index;

  }

  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: this.translatedText.oops,//'Please Tap Again',
      message: '<h2>'+ this.translatedText.redirect_back_5_seconds +'</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
          }
        }
      ]
    });
    setTimeout(() => {
      let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
      this.alertController.dismiss();
    }, 5000);
    (await alert).present();
  }

  isItemCartAvaliable() {
    this.kiosk_apiService.isItemCartAvaliable().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.checkOut()
        }
        else {
          clearInterval(this.time_left_redirection_counter)
          clearInterval(this.time_left_counter);
          this.soldItemModal(res)
        }
      }
    )
  }

  ella_drink_limit() {
    console.log("ella drinks called");
    this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          this.ella_limit = res.ella_max_drinks;
          this.store.set('ellaMaxLimitCount',res.ella_max_drinks)
          console.log("ella drinks called res");
          this.getCartItems();
        }, error=>{
          console.log("ella drinks called error");
          this.getCartItems();
        },
      );
  }


  async soldItemModal(data) {
    let itemImage = data.message.item_image
    if (itemImage == undefined || itemImage == '') {
      itemImage = "assets/image/bell.svg"
    }
    else {
      itemImage = data.message.item_image
    }
    let message = data.message.error
    let html = `<div class="custom-head">
    <span><img src="`+ itemImage + `"></span>
    <h2>`+ this.translatedText.sorry +`</h2>
    <p>`+ message + `</p>
    </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal no-default-image',
      mode: 'md',
      message: html,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.reset_time();
            if (this.cartItemCount != 0) {
              this.time_left_counter = setInterval(() => {
                this.time_left -= 1;
                if (this.time_left == 0) {
                  clearInterval(this.time_left_redirection_counter);
                  this.store.setUserCart([]);
                  this.store.remove("formFields");
                  this.store.remove("cart");
                  let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
                  return false;
                }
              }, setIntervalTimeOut);
            }
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_redirection_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
            return false;
          }
        }, setIntervalTimeOut);
      }
      this.reset_time();
    }, 5000);
    await alert.present();
  }

  handleChange($event) {
    console.log('handleChange($event)', $event)
    const language = {};
    language['language_uuid'] = '';
    language['language_code'] = $event.detail.value;
    this.store.set("language",language);
    this.setLanguage = $event.detail.value;
    this.ionViewWillEnter();
    this.ionViewDidEnter();
    // this.translateConfigService.setLanguage($event.detail.value)
  }
  languageSelected(selectedLocal) {
    console.log("languageSelected", selectedLocal);
    const language = {};
    language['language_uuid'] = '';
    language['language_code'] = selectedLocal.language_locale;
    this.store.set("language",language);
    this.store.set("languageLocal",selectedLocal);
    this.setLanguage = selectedLocal.language_locale;
    this.selectedLanguage = selectedLocal;
    this.isLanguageModalActive = !this.isLanguageModalActive;
    this.ionViewWillEnter();
    this.ionViewDidEnter();
    this.getRestaurantDetails();
  }
  
  isLanguageModalActive = false;
  openLanguageModal() {
    this.isLanguageModalActive = !this.isLanguageModalActive;
    this.reset_time();
  }

  openMenu(index) {
    console.log("menu index", index)
    this.clickedEement = index;
    this.menuOffset.nativeElement.scrollIntoView({behavior: "instant", block: "start", inline: "nearest"});
  }

  // scrollToElement(clickedEement): void {
  //   this.clickedEement = clickedEement;
  //   this.clickEventCalled = true;
  //   this.targets.toArray()[clickedEement].nativeElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  // }

  ionScroll(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
    // if (!this.clickEventCalled) {
    //   this.targets.toArray().forEach((element, index) => {
    //     if (event.detail.scrollTop > (element.nativeElement.offsetTop - 0)) {
    //       this.clickedEement = index;
    //       this.targetsThumb.toArray()[this.clickedEement].nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    //     }
    //   });
    // }
  }

  resetCart() {
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    this.store.remove(this.store.getUser().user_information_uuid);
    this.kiosk_apiService.logEvents("CART_ITEM_REMOVED");
    this.kiosk_apiService.audit_log("CART_ITEM_REMOVED");
    clearInterval(this.time_left_redirection_counter);
    this.kiosk_apiService.terminal_dismiss()
    clearInterval(this.telemetry_poller)
    let Autologin = this.store.get('autoLoginUrl')
    this.store.hardlogout()
    location.href = DOMAIN_URL + Autologin;
    // if (this.clickedEement > 0) {
    //   this.clickEventCalled = true;
    //   setTimeout(()=>{
    //     this.clickEventCalled = false;
    //   },1500);
    //   this.clickedEement = 0;
    //   const offSet = this.targets.toArray()[0].nativeElement.offsetTop;
    //   console.log("offSet",offSet)
    //   this.targets.toArray()[0].nativeElement.scrollIntoView(
    //     {behavior: "smooth", block: "start", inline: "nearest"}
    //     );
    // }
    
  }
  langaugeList = [];
  obj_langaugeList;
  translatedText;
  selectedLanguage;
  getLanguageList() {
    //Get Menu Details
    this.kiosk_apiService.apiReq('get', 'mastercountry/getlanguages')
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          this.langaugeList = response.aaData;
          if (!this.setLanguage) {
            this.setLanguage = this.langaugeList[0].language_locale;
          }
          if (!this.selectedLanguage) {
            this.selectedLanguage = this.langaugeList[0];
            this.store.set("languageLocal",this.selectedLanguage);
          }
        }
      },
        error => {
          // this.technical_error()
        }
      );
  }
  getTranslatedText() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    this.kiosk_apiService.apiReq('get', 'appsetting/text?master_restaurant_uuid=' + master_restaurant_uuid)
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          this.translatedText = response.aaData;
          this.store.set('translatedText', this.translatedText);
        }
      },
        error => {
          // this.technical_error()
        }
      );
  }
  doesModalPresent = false
  goToCartList() {
    this.router.navigateByUrl('/cart-list');
    if (this.doesModalPresent) {
      this.modalController.dismiss();
    }
  }

  async provideOrderFeedback() {
    this.doesModalPresent = true;
    clearInterval(this.store_close_status);
    clearInterval(this.restaurant_mode);
    clearInterval(this.telemetry_poller)
    clearInterval(this.terminal_modal)
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    clearInterval(this.time_left_redirection_counter);
    const modal = await this.modalController.create({
      component: EllaMaxDrinkModalPage,
      cssClass: 'ella-maxdrink-modal',
      backdropDismiss: false,
      componentProps: {
        'notificationTest': this.translatedText?.ella_max_drink_modal_text,
        'checkOutBtnText': this.translatedText?.check_out,
        'resetBtnText' : this.translatedText?.reset,
        'orText' : this.translatedText?.or,
        'maxDrinksAllowed' : this.ella_limit
      }
    });
    modal.onDidDismiss().then((data) => {
      console.log("feedbackSucess", data);
    });
    return await modal.present();
  }
  // ngAfterContentChecked() { 
  //   console.log('ngAfterContentChecked called')
  //  }
  //  ngAfterViewChecked() {
  //   console.log('AfterViewChecked called')
  //  }

} 